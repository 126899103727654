import {ActionTypes} from "./type";

export const FormsReducer = (state = {
    fetch_codes: {
        success: false,
        loading: false,
        error: null,
        data: null
    }
},action) => {
    switch (action.type) {
        //create
        case ActionTypes.FETCH_CODES_REQUEST:
            return {
                ...state,
                fetch_codes: {
                    ...state.fetch_codes,
                    loading: true,
                    success: false,
                    error: null
                }
            }
        case ActionTypes.FETCH_CODES_SUCCESS:
            return {
                ...state,
                fetch_codes: {
                    ...state.fetch_codes,
                    success: true,
                    loading: false,
                    data: action.payload,
                    [action.tag]: action.payload
                }
            }
        case ActionTypes.FETCH_CODES_ERROR:
            return {
                ...state,
                fetch_codes: {
                    ...state.fetch_codes,
                    success: false,
                    loading: false,
                    error: action.payload
                }
            }

        default:
            return state

    }
}

